import Phaser from 'phaser';

import Popup from './Popup';
import Button from '../button/Button';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { fontFamilies, colors, fontSizes } from '../../../../utils/styles';
import { customFormat, formatter } from '../../../../utils/numbers';
import { formatUsername } from '../../../../utils/strings';
import { fibonacis, maxStepCount, maxStepSizeIndex } from '../../../../utils/constants';

const { width, height } = configs;

const buttonWidth = 506;
const largeBlackExtraBold = {
  fontSize: '72px',
  color: '#30030B',
  fontFamily: fontFamilies.extraBold,
};
const mediumBrownBold = { fontSize: fontSizes.medium, color: colors.brown, fontFamily: fontFamilies.bold };
const btnGap = 50;
const INTERVAL = 100;

class PopupWarMachines extends Popup {
  status = null;
  initEarnUnits = 0;
  initAttackUnits = 0;
  initDefendUnits = 0;
  initTargets = [];
  items = [];

  tokenRewardPerEarner = 0;
  numberOfMachines = 0;
  numberOfAvailableMachines = 0;
  totalPistolBonus = 0;
  totalShieldBonus = 0;
  earnUnits = 0;
  attackUnits = 0;
  defendUnits = 0;
  targets = [];
  totalEarn = 0;
  loading = false;
  stepSizeIndex = 0;
  stepCount = 0;
  defenseMinTimesToApplyMultiplier = 0;
  raidPointBonusPercent = 0;
  defenseMultiple = 0;

  constructor(scene, { onClickInfoButton } = {}) {
    super(scene, 'popup-war-machines', { title: 'Gang War', noBackgroundClick: true });
    this.scene = scene;

    this.addTargetButtonY = height / 2 - this.popup.height / 2 + 570;
    const targetGangsterY = this.addTargetButtonY + 250;
    const targetGangsterCounterY = targetGangsterY + 110;
    const pistolBonusY = targetGangsterCounterY + 110;
    const counterBtnOffset = 100;

    this.backBtn = new TextButton(
      scene,
      width / 2 - buttonWidth / 2 - btnGap / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.earnUnits = this.initEarnUnits;
        this.attackUnits = this.initAttackUnits;
        this.defendUnits = this.initDefendUnits;
        this.targets = JSON.parse(JSON.stringify(this.initTargets));
        this.updateValues();

        this.close();
      },
      'Cancel',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.backBtn);

    this.raidBtn = new TextButton(
      scene,
      width / 2 + buttonWidth / 2 + btnGap / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        if (this.loading) return;
        this.loading = true;
        scene.events.emit('s-update-war-machines', {
          numberOfMachinesToEarn: this.earnUnits,
          numberOfMachinesToDefend: this.defendUnits,
          attackUsers: this.targets,
        });
      },
      'Save',
      { sound: 'button-1', fontSize: '82px', disabledImage: 'button-disabled' }
    );
    this.add(this.raidBtn);

    const infoButton = new Button(
      scene,
      width / 2 + this.popup.width / 2 - 210,
      height / 2 - this.popup.height / 2 + 250,
      'button-info',
      'button-info-pressed',
      () => {
        this.close();
        scene.popupWarExplain?.open();
        onClickInfoButton?.();
      },
      { sound: 'open' }
    );
    this.add(infoButton);

    const targetXs = [width / 2 - 330, width / 2, width / 2 + 330];
    const addTargetBtnKeys = ['addTargetButton1', 'addTargetButton2', 'addTargetButton3'];
    const gangsterContainerKeys = ['gangsterContainer1', 'gangsterContainer2', 'gangsterContainer3'];
    const gangsterCounterKeys = ['gangsterCounter1', 'gangsterCounter2', 'gangsterCounter3'];
    const gangsterCounterMinusBtnKeys = [
      'gangsterCounter1MinusBtn',
      'gangsterCounter2MinusBtn',
      'gangsterCounter3MinusBtn',
    ];
    const gangsterCounterPlusBtnKeys = [
      'gangsterCounter1PlusBtn',
      'gangsterCounter2PlusBtn',
      'gangsterCounter3PlusBtn',
    ];
    const pistolBonusContainerKeys = ['pistolBonusContainer1', 'pistolBonusContainer2', 'pistolBonusContainer3'];
    const pistolBonusTextKeys = ['pistolBonusText1', 'pistolBonusText2', 'pistolBonusText3'];

    for (let [index, x] of targetXs.entries()) {
      const addTargetBtnKey = addTargetBtnKeys[index];
      const gangsterContainerKey = gangsterContainerKeys[index];
      const gangsterCounterKey = gangsterCounterKeys[index];
      const gangsterCounterMinusBtnKey = gangsterCounterMinusBtnKeys[index];
      const gangsterCounterPlusBtnKey = gangsterCounterPlusBtnKeys[index];
      const pistolBonusContainerKey = pistolBonusContainerKeys[index];
      const pistolBonusTextKey = pistolBonusTextKeys[index];
      this[addTargetBtnKey] = new Button(
        scene,
        x,
        this.addTargetButtonY,
        'button-add-target',
        'button-add-target',
        () => {
          this.close();
          scene.popupWarAttack?.open();
        },
        { sound: 'open' }
      );
      this.add(this[addTargetBtnKey]);

      this[gangsterContainerKey] = scene.add.container();
      this[gangsterContainerKey].setAlpha(0.3);
      this.add(this[gangsterContainerKey]);
      const gangsterIcon = scene.add.image(x, targetGangsterY, 'icon-gangster-medium');
      const gangsterCounter = scene.add
        .text(x, targetGangsterCounterY, `0`, { ...largeBlackExtraBold, fontSize: '56px' })
        .setOrigin(0.5, 0.5);
      const minusBtn = new TextButton(
        scene,
        x - counterBtnOffset,
        targetGangsterCounterY,
        'button-square-tiny',
        'button-square-tiny-pressed',
        () => {
          if (this.status !== 'open') return;
          if (this.targets[index].numberOfMachines > 0) {
            this.targets[index].numberOfMachines--;
            this.updateValues();
          }
        },
        '-',
        {
          fontSize: '82px',
          sound: 'button-1',
          disabledImage: 'button-square-tiny',
          onHold: () => {
            if (this.status !== 'open') return;
            if (this.interval) {
              clearInterval(this.interval);
            }
            this.interval = setInterval(() => {
              if (this.stepCount > maxStepCount) {
                this.stepCount = 0;
                this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
              } else {
                this.stepCount++;
              }

              const stepSize = fibonacis[this.stepSizeIndex];
              if (this.targets[index].numberOfMachines > 0) {
                this.targets[index].numberOfMachines = Math.max(0, this.targets[index].numberOfMachines - stepSize);
                this.updateValues();
              }
            }, INTERVAL);
          },
          onRelease: () => {
            if (this.interval) {
              clearInterval(this.interval);
            }
            this.stepSizeIndex = 0;
            this.stepCount = 0;
          },
        }
      );
      const plusBtn = new TextButton(
        scene,
        x + counterBtnOffset,
        targetGangsterCounterY,
        'button-square-tiny',
        'button-square-tiny-pressed',
        () => {
          if (this.status !== 'open') return;
          const clonedTargets = [...this.targets];
          clonedTargets.splice(index, 1);
          const allocatedAttackMachines = clonedTargets.reduce(
            (sum, { numberOfMachines }) => sum + numberOfMachines,
            0
          );
          const max = this.numberOfAvailableMachines - this.earnUnits - this.defendUnits - allocatedAttackMachines;
          if (this.targets[index].numberOfMachines < max) {
            this.targets[index].numberOfMachines++;
            this.updateValues();
          }
        },
        '+',
        {
          fontSize: '82px',
          sound: 'button-1',
          disabledImage: 'button-square-tiny',
          onHold: () => {
            if (this.status !== 'open') return;

            if (this.interval) {
              clearInterval(this.interval);
            }
            const clonedTargets = [...this.targets];
            clonedTargets.splice(index, 1);
            const allocatedAttackMachines = clonedTargets.reduce(
              (sum, { numberOfMachines }) => sum + numberOfMachines,
              0
            );
            const max = this.numberOfAvailableMachines - this.earnUnits - this.defendUnits - allocatedAttackMachines;
            this.interval = setInterval(() => {
              if (this.stepCount > maxStepCount) {
                this.stepCount = 0;
                this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
              } else {
                this.stepCount++;
              }

              const stepSize = fibonacis[this.stepSizeIndex];
              if (this.targets[index].numberOfMachines < max) {
                this.targets[index].numberOfMachines = Math.min(max, this.targets[index].numberOfMachines + stepSize);
                this.updateValues();
              }
            }, INTERVAL);
          },
          onRelease: () => {
            if (this.interval) {
              clearInterval(this.interval);
            }
            this.stepSizeIndex = 0;
            this.stepCount = 0;
          },
        }
      );

      this[gangsterCounterKey] = gangsterCounter;
      this[gangsterCounterMinusBtnKey] = minusBtn;
      this[gangsterCounterPlusBtnKey] = plusBtn;

      this[gangsterContainerKey].add(gangsterIcon);
      this[gangsterContainerKey].add(gangsterCounter);
      this[gangsterContainerKey].add(minusBtn);
      this[gangsterContainerKey].add(plusBtn);

      this[pistolBonusContainerKey] = scene.add.container();
      this[pistolBonusContainerKey].setVisible(false);
      this.add(this[pistolBonusContainerKey]);

      const pistolBonusBgWidth = 300;
      const pistolBonusBgHeight = 73;
      const pistolBonusBg = scene.add
        .graphics()
        .fillStyle(0xf4d4aa)
        .fillRoundedRect(
          x - pistolBonusBgWidth / 2,
          pistolBonusY - pistolBonusBgHeight / 2,
          pistolBonusBgWidth,
          pistolBonusBgHeight,
          30
        );
      // pistolBonusBg.setOrigin(0.5, 0.5);
      const pistolBonusText = scene.add
        .text(x + 60, pistolBonusY, `+0 from`, {
          fontSize: '40px',
          fontFamily: fontFamilies.extraBold,
          color: colors.brown,
        })
        .setOrigin(1, 0.5);
      const pistolIcon = scene.add.image(x + 100, pistolBonusY, `icon-pistol`).setDisplaySize(55, 62);

      this[pistolBonusTextKey] = pistolBonusText;

      this[pistolBonusContainerKey].add(pistolBonusBg);
      this[pistolBonusContainerKey].add(pistolBonusText);
      this[pistolBonusContainerKey].add(pistolIcon);
    }

    const input1Y = this.popup.y + 420;
    const input2Y = input1Y + 350;
    const minusBtnX = width / 2 - this.popup.width / 2 + 180;
    const addBtnX = width / 2 - 30;

    this.machinesToEarnMinusBtn = new TextButton(
      scene,
      minusBtnX,
      input1Y,
      'button-square-tiny',
      'button-square-tiny-pressed',
      () => {
        if (this.status !== 'open') return;
        if (this.earnUnits > 0) {
          this.earnUnits--;
          this.updateValues();
        }
      },
      '-',
      {
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.status !== 'open') return;
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            if (this.earnUnits > 0) {
              if (this.stepCount > maxStepCount) {
                this.stepCount = 0;
                this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
              } else {
                this.stepCount++;
              }

              const stepSize = fibonacis[this.stepSizeIndex];
              this.earnUnits = Math.max(0, this.earnUnits - stepSize);
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
        },
      }
    );
    this.add(this.machinesToEarnMinusBtn);

    this.machinesToEarnPlusBtn = new TextButton(
      scene,
      addBtnX,
      input1Y,
      'button-square-tiny',
      'button-square-tiny-pressed',
      () => {
        if (this.status !== 'open') return;
        const max = this.numberOfAvailableMachines - this.attackUnits - this.defendUnits;
        if (this.earnUnits < max) {
          this.earnUnits++;
          this.updateValues();
        }
      },
      '+',
      {
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.status !== 'open') return;

          if (this.interval) {
            clearInterval(this.interval);
          }
          const max = this.numberOfAvailableMachines - this.attackUnits - this.defendUnits;
          this.interval = setInterval(() => {
            if (this.stepCount > maxStepCount) {
              this.stepCount = 0;
              this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
            } else {
              this.stepCount++;
            }

            const stepSize = fibonacis[this.stepSizeIndex];
            if (this.earnUnits < max) {
              this.earnUnits = Math.min(max, this.earnUnits + stepSize);
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
        },
      }
    );
    this.add(this.machinesToEarnPlusBtn);

    this.machinesToDefendMinusBtn = new TextButton(
      scene,
      minusBtnX,
      input2Y,
      'button-square-tiny',
      'button-square-tiny-pressed',
      () => {
        if (this.status !== 'open') return;

        if (this.defendUnits > 0) {
          this.defendUnits--;
          this.updateValues();
        }
      },
      '-',
      {
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.status !== 'open') return;

          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            if (this.stepCount > maxStepCount) {
              this.stepCount = 0;
              this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
            } else {
              this.stepCount++;
            }

            const stepSize = fibonacis[this.stepSizeIndex];
            if (this.defendUnits > 0) {
              this.defendUnits = Math.max(0, this.defendUnits - stepSize);
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
        },
      }
    );
    this.add(this.machinesToDefendMinusBtn);

    this.machinesToDefendPlusBtn = new TextButton(
      scene,
      addBtnX,
      input2Y,
      'button-square-tiny',
      'button-square-tiny-pressed',
      () => {
        if (this.status !== 'open') return;

        const max = this.numberOfAvailableMachines - this.attackUnits - this.earnUnits;
        if (this.defendUnits < max) {
          this.defendUnits++;
          this.updateValues();
        }
      },
      '+',
      {
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.status !== 'open') return;

          if (this.interval) {
            clearInterval(this.interval);
          }
          const max = this.numberOfAvailableMachines - this.attackUnits - this.earnUnits;
          this.interval = setInterval(() => {
            if (this.stepCount > maxStepCount) {
              this.stepCount = 0;
              this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
            } else {
              this.stepCount++;
            }

            const stepSize = fibonacis[this.stepSizeIndex];
            if (this.defendUnits < max) {
              this.defendUnits = Math.min(max, this.defendUnits + stepSize);
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
        },
      }
    );
    this.add(this.machinesToDefendPlusBtn);

    this.numberOfDeployedGangsters = scene.add
      .text(
        width / 2,
        height / 2 - this.popup.height / 2 + 340,
        `Gangsters Deployed: ${this.earnUnits + this.attackUnits + this.defendUnits}/${this.numberOfAvailableMachines}`,
        { ...mediumBrownBold, fontSize: '40px' }
      )
      .setOrigin(0.5, 0.5);
    this.add(this.numberOfDeployedGangsters);

    this.augmentText = scene.add
      .text(width / 2, height / 2 + 150, `Augment: --`, {
        fontSize: '48px',
        color: '#ffffff',
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0.5, 0.5);
    this.add(this.augmentText);

    this.earnUnitsText = scene.add
      .text((minusBtnX + addBtnX) / 2, input1Y, `0`, largeBlackExtraBold)
      .setOrigin(0.5, 0.5);
    this.add(this.earnUnitsText);

    this.defendUnitsText = scene.add
      .text((minusBtnX + addBtnX) / 2, input2Y, `0`, largeBlackExtraBold)
      .setOrigin(0.5, 0.5);
    this.add(this.defendUnitsText);

    this.totalBonusText = scene.add
      .text(this.popup.x + 260, input1Y, ``, {
        fontSize: '64px',
        color: '#fff',
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0, 0.5);
    this.totalBonusText.setStroke(colors.brown, 12);
    this.add(this.totalBonusText);

    this.defendBonusText = scene.add
      .text(this.popup.x + 270, input2Y, ``, {
        fontSize: '64px',
        color: '#fff',
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0, 0.5);
    this.defendBonusText.setStroke(colors.brown, 12);
    this.add(this.defendBonusText);

    scene.events.on('s-set-season-status', ({ status }) => {
      this.status = status;
      if (status !== 'open') {
        this.raidBtn?.setDisabledState(true);
      } else {
        this.raidBtn?.setDisabledState(false);
      }
    });

    scene.events.on(
      's-set-game-play',
      ({
        numberOfMachines,
        totalPistolBonus,
        totalShieldBonus,
        numberOfAvailableMachines,
        numberOfMachinesToEarn,
        numberOfMachinesToAttack,
        numberOfMachinesToDefend,
        attackUsers,
        tokenRewardPerEarner,
        defenseMinTimesToApplyMultiplier,
        raidPointBonusMultiple,
        defenseMultiple,
      }) => {
        this.initEarnUnits = numberOfMachinesToEarn;
        this.initAttackUnits = numberOfMachinesToAttack;
        this.initDefendUnits = numberOfMachinesToDefend;
        this.tokenRewardPerEarner = tokenRewardPerEarner;
        this.initTargets = JSON.parse(JSON.stringify(attackUsers));

        this.numberOfMachines = numberOfMachines;
        this.totalPistolBonus = totalPistolBonus;
        this.totalShieldBonus = totalShieldBonus;
        this.numberOfAvailableMachines = numberOfAvailableMachines;
        this.totalEarn = numberOfMachinesToEarn * tokenRewardPerEarner;
        this.earnUnits = numberOfMachinesToEarn;
        this.attackUnits = numberOfMachinesToAttack;
        this.defendUnits = numberOfMachinesToDefend;
        this.targets = JSON.parse(JSON.stringify(attackUsers));
        this.defenseMinTimesToApplyMultiplier = defenseMinTimesToApplyMultiplier;
        this.defenseMultiple = defenseMultiple;
        this.raidPointBonusPercent = raidPointBonusMultiple * 100;

        this.updateValues();
      }
    );

    scene.events.on('s-set-user-augments', (data) => {
      const { selected, currentAugmentUseCount } = data;
      if (selected && !selected.type.includes('instant') && !!currentAugmentUseCount) this.selectedAugment = selected;
      else this.selectedAugment = null;
      this.updateValues();
    });

    scene.events.on('s-update-war-machines-completed', ({ numberOfMachinesToAttack }) => {
      this.loading = false;
      if (this.visible) {
        this.close();
      }
    });

    scene.events.on('s-update-war-machines-error', () => {
      this.loading = false;
    });
  }

  onOpen() {
    this.scene.events.emit('s-get-game-play');
    this.scene.events.emit('s-get-user-augments');
  }

  updateValues() {
    this.attackUnits = this.targets.reduce((sum, { numberOfMachines }) => sum + numberOfMachines, 0);
    this.numberOfDeployedGangsters.text = `Gangsters Deployed: ${
      this.earnUnits + this.attackUnits + this.defendUnits
    }/${this.numberOfAvailableMachines}`;

    if (this.selectedAugment) {
      let valuePrefix = '';
      let valueSuffix = '';
      switch (this.selectedAugment.type) {
        // multipliers
        case 'attack-multiplier':
        case 'defence-multiplier':
        case 'earnings-multiplier':
          valueSuffix = 'x';
          break;
        case 'attack-bonus':
        case 'defence-bonus':
        case 'earnings-bonus':
        case 'xgreed-instant-bonus':
          valuePrefix = '+';
          break;
      }

      this.augmentText.text = `Augment: ${this.selectedAugment.name} ${valuePrefix}${formatter.format(
        this.selectedAugment.value
      )}${valueSuffix}`;
    } else {
      this.augmentText.text = `Augment: --`;
    }
    this.earnUnitsText.text = `${this.earnUnits}`;
    this.defendUnitsText.text = `${this.defendUnits}`;

    let tokensEarned = this.earnUnits * this.tokenRewardPerEarner;
    if (this.selectedAugment?.type === 'earnings-bonus') tokensEarned += this.selectedAugment.value;
    if (this.selectedAugment?.type === 'earnings-multiplier') tokensEarned *= this.selectedAugment.value;
    this.totalBonusText.text = `+${formatter.format(tokensEarned)}`;

    let totalDefence = this.defendUnits + this.totalShieldBonus;
    if (this.selectedAugment?.type === 'defence-bonus') totalDefence += this.selectedAugment.value;
    if (this.selectedAugment?.type === 'defence-multiplier') totalDefence *= this.selectedAugment.value;
    this.defendBonusText.text = `${formatter.format(totalDefence)}`;

    // render targets
    this.items.map((item) => item.destroy());
    this.items = [];
    const targetXs = [width / 2 - 330, width / 2, width / 2 + 330];
    const addTargetBtns = [this.addTargetButton1, this.addTargetButton2, this.addTargetButton3];
    const avatars = {};
    const gangsterContainers = [this.gangsterContainer1, this.gangsterContainer2, this.gangsterContainer3];
    const gangsterCounters = [this.gangsterCounter1, this.gangsterCounter2, this.gangsterCounter3];
    const gangsterCounterMinusBtns = [
      this.gangsterCounter1MinusBtn,
      this.gangsterCounter2MinusBtn,
      this.gangsterCounter3MinusBtn,
    ];
    const gangsterCounterPlusBtns = [
      this.gangsterCounter1PlusBtn,
      this.gangsterCounter2PlusBtn,
      this.gangsterCounter3PlusBtn,
    ];
    const pistolBonusContainers = [this.pistolBonusContainer1, this.pistolBonusContainer2, this.pistolBonusContainer3];
    const pistolBonusTexts = [this.pistolBonusText1, this.pistolBonusText2, this.pistolBonusText3];
    const totalAttackGangsters = this.targets.reduce((sum, target) => sum + (target.numberOfMachines || 0), 0);
    for (let index = 0; index < 3; index++) {
      const target = this.targets[index];
      const x = targetXs[index];
      const addTargetBtn = addTargetBtns[index];
      const gangsterContainer = gangsterContainers[index];
      const gangsterCounter = gangsterCounters[index];
      const minusBtn = gangsterCounterMinusBtns[index];
      const plusBtn = gangsterCounterPlusBtns[index];
      const pistolBonusContainer = pistolBonusContainers[index];
      const pistolBonusText = pistolBonusTexts[index];
      if (target) {
        const { userId, username, numberOfMachines = 0 } = target;
        gangsterCounter.text = customFormat(numberOfMachines, 2);
        addTargetBtn.setDisabledState(true);
        const avatar = this.scene.add.rexCircleMaskImage(x, this.addTargetButtonY, 'avatar').setOrigin(0.5, 0.5);
        avatars[username] = avatar;

        const targetUsername1 = this.scene.add
          .text(
            x,
            this.addTargetButtonY + 65,
            `${formatUsername({ username, MAX_USERNAME_LENGTH: 8 })}`,
            mediumBrownBold
          )
          .setOrigin(0.5, 0);
        targetUsername1.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
          this.close();
          this.scene.popupWarAttackDetail?.viewUser({ userId, onBack: () => this.open() });
        });
        const targetUsername1Underline = this.scene.add.rectangle(
          x,
          this.addTargetButtonY + 120,
          targetUsername1.width,
          3,
          0x7c2828
        );
        const removeTargetBtn = this.scene.add.image(x + 40, this.addTargetButtonY - 45, 'button-delete');
        removeTargetBtn.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
          this.removeTarget(index);
        });

        gangsterContainer.setAlpha(1);
        minusBtn.setDisabledState(false);
        plusBtn.setDisabledState(false);
        gangsterCounter.text = customFormat(numberOfMachines, 2);

        this.add(avatar);
        this.add(removeTargetBtn);
        this.add(targetUsername1);
        this.add(targetUsername1Underline);
        this.items.push(avatar, removeTargetBtn, targetUsername1, targetUsername1Underline);

        pistolBonusContainer.setVisible(true);
        const attackPercent = numberOfMachines / totalAttackGangsters;
        let bonusAttack = this.totalPistolBonus * attackPercent;
        if (this.selectedAugment?.type === 'attack-bonus') bonusAttack += this.selectedAugment.value * attackPercent;
        if (this.selectedAugment?.type === 'attack-multiplier') {
          const totalAttackOnTarget = (numberOfMachines + bonusAttack) * this.selectedAugment.value;
          bonusAttack = totalAttackOnTarget - numberOfMachines;
        }

        pistolBonusText.text = `+${customFormat(totalAttackGangsters ? bonusAttack : 0, 1)} from`;
      } else {
        setTimeout(() => addTargetBtn.setDisabledState(false), 200);
        gangsterCounter.text = '0';
        gangsterContainer.setAlpha(0.3);
        minusBtn.setDisabledState(true);
        plusBtn.setDisabledState(true);

        pistolBonusContainer.setVisible(false);
        pistolBonusText.text = `+0 from`;
      }
    }
    // load avatar
    let loader = new Phaser.Loader.LoaderPlugin(this.scene);
    let textureManager = new Phaser.Textures.TextureManager(this.scene.game);
    this.targets.forEach(({ username, avatarURL }) => {
      // ask the LoaderPlugin to load the texture
      if (!textureManager.exists(`${username}-avatar`)) loader.image(`${username}-avatar`, avatarURL);
    });

    loader.once(Phaser.Loader.Events.COMPLETE, () =>
      Object.keys(avatars).forEach((username) => {
        if (!textureManager.exists(`${username}-avatar`)) {
          const avatar = avatars[username];
          if (avatar) {
            avatar.setTexture(`${username}-avatar`);
            avatar.setDisplaySize(125, 125);
          }
        }
      })
    );
    try {
      loader.start();
    } catch (err) {
      console.error(err);
    }
  }

  addTarget(user) {
    if (this.targets.length === 3 || this.targets.some(({ userId }) => userId === user.userId)) return;
    const { userId, username, avatarURL, numberOfMachines = 0 } = user;
    this.targets.push({ userId, username, avatarURL, numberOfMachines });
    this.updateValues();
    this.setVisible(true);
  }

  removeTarget(index) {
    this.targets.splice(index, 1);
    this.updateValues();
  }
}

export default PopupWarMachines;
