import Popup from './Popup';
import Button from '../button/Button';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { customFormat, formatter } from '../../../../utils/numbers';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';

const { width, height } = configs;

const buttonWidth = 506;
const btnGap = 50;
const lineGap = 128;

class PopupPortfolio extends Popup {
  ETHBalance = 0;
  tokenValue = 0;
  machineValue = 0;
  stashValue = 0;
  userRankRewardInEth = 0;
  userRankRewardInToken = 0;

  constructor(scene, parentModal) {
    super(scene, 'popup-portfolio', { title: 'Portfolio' });
    this.scene = scene;

    this.setVisible(false);

    this.backBtn = new TextButton(
      scene,
      width / 2 - buttonWidth / 2 - btnGap / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        parentModal.open();
      },
      'Back',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.backBtn);

    this.statisticBtn = new TextButton(
      scene,
      width / 2 + buttonWidth / 2 + btnGap / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        scene.popupStatistic.open();
      },
      'Statistics',
      { sound: 'button-1', fontSize: '82px' }
    );
    this.add(this.statisticBtn);

    this.addressText = scene.add
      .text(this.popup.x - 150, this.popup.y - this.popup.height / 2 + 200, '', {
        fontSize: fontSizes.large,
        color: colors.brown,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0, 0.5);
    this.add(this.addressText);

    this.copyBtn = this.buttonCopy = new Button(
      scene,
      this.addressText.x + this.addressText.width + 120,
      this.addressText.y,
      'button-copy',
      'button-copy-pressed',
      () => navigator.clipboard.writeText(this.address || ''),
      { sound: 'button-2' }
    );
    this.add(this.copyBtn);

    this.totalBalanceText = scene.add
      .text(this.popup.x + 10, this.addressText.y + 265, '', {
        fontSize: fontSizes.large,
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(1, 0.5);
    this.add(this.totalBalanceText);

    this.balanceText = scene.add
      .text(this.popup.x + 350, this.totalBalanceText.y + 135, '', {
        fontSize: '48px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5);
    this.add(this.balanceText);

    this.tokenBalanceText = scene.add
      .text(this.popup.x - 330, this.balanceText.y + lineGap, '', {
        fontSize: '48px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0, 0.5);
    this.add(this.tokenBalanceText);

    this.tokenValueText = scene.add
      .text(this.balanceText.x, this.tokenBalanceText.y, '0', {
        fontSize: '48px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5);
    this.add(this.tokenValueText);

    this.rewardInEthText = scene.add
      .text(this.balanceText.x, this.tokenValueText.y + lineGap, '0.00', {
        fontSize: '48px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5);
    this.rewardInTokenText = scene.add
      .text(this.balanceText.x, this.rewardInEthText.y + 100, '0', {
        fontSize: '48px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5);
    this.add(this.rewardInEthText);
    this.add(this.rewardInTokenText);

    this.stashEthText = scene.add
      .text(this.balanceText.x, this.rewardInTokenText.y + lineGap, '0.00', {
        fontSize: '48px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5);
    this.stashTokenText = scene.add
      .text(this.balanceText.x, this.stashEthText.y + 100, '0', {
        fontSize: '48px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5);
    this.add(this.stashEthText);
    this.add(this.stashTokenText);

    this.blastGoldText = scene.add
      .text(this.balanceText.x + 95, this.stashTokenText.y + lineGap, '0', {
        fontSize: '48px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5);
    this.add(this.blastGoldText);

    this.xUText = scene.add
      .text(this.balanceText.x + 95, this.blastGoldText.y + lineGap, '0', {
        fontSize: '48px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5);
    this.add(this.xUText);

    this.blastPointText = scene.add
      .text(this.balanceText.x + 95, this.xUText.y + lineGap, '0', {
        fontSize: '48px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5);
    this.add(this.blastPointText);

    scene.events.on('s-set-portfolio', ({ address, ETHBalance, tokenBalance, tokenPrice, stash, blastPointReward }) => {
      this.address = address;
      this.addressText.text = this.formatAddress(address);

      const tokenValue = tokenBalance * tokenPrice;
      this.ETHBalance = ETHBalance;
      this.tokenValue = tokenValue;
      this.tokenPrice = tokenPrice;
      this.stashValue = stash.eth + stash.token * tokenPrice;
      this.updateTotalBalance();

      this.copyBtn.x = this.addressText.x + this.addressText.width + 120;
      this.balanceText.text = `~${formatter.format(ETHBalance)}`;
      this.tokenBalanceText.text = `${formatter.format(tokenBalance)}`;
      this.tokenValueText.text = `~${formatter.format(tokenValue)}`;
      this.stashEthText.text = `${formatter.format(stash.eth)}`;
      this.stashTokenText.text = `${formatter.format(stash.token)}`;
      this.blastPointText.text = `${formatter.format(blastPointReward)}`;
    });

    scene.events.on('s-set-portfolio-rewards', ({ rankEthReward, rankTokenReward, xU, blastGold }) => {
      this.userRankRewardInEth = rankEthReward;
      this.userRankRewardInToken = rankTokenReward;
      this.updateTotalBalance();

      this.rewardInEthText.text = `${formatter.format(rankEthReward)}`;
      this.rewardInTokenText.text = `${customFormat(rankTokenReward || 0, 2)}`;
      this.xUText.text = `${formatter.format(xU)}`;
      this.blastGoldText.text = `${formatter.format(blastGold)}`;
    });
  }

  onOpen() {
    this.scene.events.emit('s-get-portfolio');
    this.scene.events.emit('s-get-portfolio-rewards');
  }

  formatAddress(address) {
    return `${address.slice(0, 5)}...${address.slice(-5)}`;
  }

  updateTotalBalance() {
    const userRankRewardInTokenValue = this.userRankRewardInToken * this.tokenPrice;
    const totalBalance =
      this.ETHBalance + this.tokenValue + this.stashValue + this.userRankRewardInEth + userRankRewardInTokenValue;
    this.totalBalanceText.text = formatter.format(totalBalance);
  }
}

export default PopupPortfolio;
