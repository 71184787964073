import Phaser from 'phaser';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { customFormat, formatter } from '../../../../utils/numbers';
import { formatUsername } from '../../../../utils/strings';

const { width, height } = configs;

const buttonWidth = 506;
const btnGap = 50;
const rowHeight = 139;
const smallBlackBoldCenter = {
  fontSize: fontSizes.small,
  color: colors.black,
  fontFamily: fontFamilies.bold,
  align: 'center',
};

const MAX_USERNAME_LENGTH = 12;

class PopupWarAttackDetail extends Popup {
  loading = false;
  userId = null;
  user = null;
  networth = 0;
  stolenNetworthPercent = 0;
  gamePlay = null;
  warResults = null;
  resultListY = height / 2 - this.popup.height / 2 + 410;
  resultItems = [];
  earnItems = [];

  constructor(scene) {
    super(scene, 'popup-user-details', { title: 'Profile' });
    this.scene = scene;
    this.earnListY = this.resultListY + 540;
    const numberOfAssetTextY = height / 2 + 620;
    const numberOfAssetTextGap = 240;
    const numberOfAssetTextBaseX = width / 2 + 50;
    const stashY = numberOfAssetTextY + 160;

    this.backBtn = new TextButton(
      scene,
      width / 2 - buttonWidth / 2 - btnGap / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.loading = false;
        this.close();
        this.onBack?.();
        this.onBack = null;
      },
      'Back',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.backBtn);

    this.attackBtn = new TextButton(
      scene,
      width / 2 + buttonWidth / 2 + btnGap / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.loading = false;
        this.scene.popupWarMachines?.addTarget({
          userId: this.userId,
          username: this.user.username,
          avatarURL: this.user.avatarURL,
        });
        this.close();
      },
      'Raid',
      { sound: 'button-1', fontSize: '82px' }
    );
    this.add(this.attackBtn);

    this.usernameText = scene.add
      .text(width / 2 + 70, height / 2 - this.popup.height / 2 + 175, '', {
        fontSize: '72px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0.5, 0.5);
    this.add(this.usernameText);

    const assetsTextStyle = { fontSize: '56px', color: '#fff', fontFamily: fontFamilies.bold };
    this.numberOfMachinesText = scene.add
      .text(numberOfAssetTextBaseX - numberOfAssetTextGap, numberOfAssetTextY, '', assetsTextStyle)
      .setStroke(colors.brown, 10)
      .setOrigin(0.5, 0.5);
    this.numberOfWorkersText = scene.add
      .text(numberOfAssetTextBaseX, numberOfAssetTextY, '', assetsTextStyle)
      .setStroke(colors.brown, 10)
      .setOrigin(0.5, 0.5);
    this.numberOfBuildingsText = scene.add
      .text(numberOfAssetTextBaseX + numberOfAssetTextGap, numberOfAssetTextY, '', assetsTextStyle)
      .setStroke(colors.brown, 10)
      .setOrigin(0.5, 0.5);
    this.add(this.numberOfMachinesText);
    this.add(this.numberOfWorkersText);
    this.add(this.numberOfBuildingsText);

    this.stashEth = scene.add
      .text(width / 2 - 105, stashY, '--', {
        fontSize: fontSizes.medium,
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(1, 0.5);
    this.stashToken = scene.add
      .text(width / 2 + 170, stashY, '--', {
        fontSize: fontSizes.medium,
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(1, 0.5);
    this.add(this.stashEth);
    this.add(this.stashToken);

    this.resultListContainer = scene.add.image(width / 2, this.resultListY, 'container-small').setOrigin(0.5, 0);
    this.add(this.resultListContainer);
    this.resultContentContainer = scene.add.container().setSize(this.popup.width * 0.8, 0);

    this.earnListContainer = scene.add.image(width / 2, this.earnListY, 'container-small').setOrigin(0.5, 0);
    this.add(this.earnListContainer);
    this.earnContentContainer = scene.add.container().setSize(this.popup.width * 0.8, 0);

    this.loadingIconResults = scene.add
      .image(this.popup.x, this.resultListContainer.y + this.resultListContainer.height / 2, 'icon-loading-small')
      .setVisible(false);
    this.loadingIconEarn = scene.add
      .image(this.popup.x, this.earnListContainer.y + this.earnListContainer.height / 2, 'icon-loading-small')
      .setVisible(false);
    this.add(this.loadingIconResults);
    this.add(this.loadingIconEarn);
    this.loadingAnimationResults = scene.tweens.add({
      targets: this.loadingIconResults,
      rotation: Math.PI * 2, // full circle
      duration: 3000,
      repeat: -1, // infinite
      ease: 'Cubic.out',
    });
    this.loadingAnimationEarn = scene.tweens.add({
      targets: this.loadingIconEarn,
      rotation: Math.PI * 2, // full circle
      duration: 3000,
      repeat: -1, // infinite
      ease: 'Cubic.out',
    });
    this.loadingAnimationResults.pause();
    this.loadingAnimationEarn.pause();

    scene.events.on('s-set-user-to-attack-detail', ({ user, gamePlay, warResults }) => {
      if (user?.id !== this.userId || !this.visible) return;
      this.user = user;
      this.gamePlay = gamePlay;
      this.warResults = warResults;
      this.updateDetail();
      this.hideLoading();
      this.loading = false;
    });
    scene.events.on('s-set-war-config', ({ stolenNetworthPercent, sizeAdjustment }) => {
      this.stolenNetworthPercent = stolenNetworthPercent;
      if (!this.visible) return;
      this.updateDetail();
    });
  }

  showLoading() {
    this.loadingAnimationResults.resume();
    this.loadingAnimationEarn.resume();
    this.loadingIconResults.setVisible(true);
    this.loadingIconEarn.setVisible(true);
  }

  hideLoading() {
    this.loadingAnimationResults.pause();
    this.loadingAnimationEarn.pause();
    this.loadingIconResults.setVisible(false);
    this.loadingIconEarn.setVisible(false);
  }

  viewUser({ userId, onBack }) {
    this.userId = userId;
    this.onBack = onBack;
    this.scene.events.emit('s-get-user-to-attack-detail', { userId: this.userId });
    this.showLoading();
    this.loading = true;
    this.scene.events.emit('s-get-war-config');
    this.open();
  }

  updateDetail() {
    if (!this.user || !this.gamePlay || !this.warResults) return;

    const { username, avatarURL } = this.user;
    this.usernameText.text = formatUsername({ username, MAX_USERNAME_LENGTH: 14 });
    // load avatar
    let loader = new Phaser.Loader.LoaderPlugin(this.scene);
    // ask the LoaderPlugin to load the texture
    loader.image(`${username}-avatar`, avatarURL);
    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.avatar = this.scene.add
        .rexCircleMaskImage(
          this.usernameText.x - this.usernameText.width / 2 - 70,
          height / 2 - this.popup.height / 2 + 175,
          `${username}-avatar`
        )
        .setOrigin(0.5, 0.5)
        .setDisplaySize(108, 108);

      this.add(this.avatar);
    });
    loader.start();

    const { numberOfMachines, numberOfWorkers, numberOfBuildings, stash } = this.gamePlay;
    this.numberOfMachinesText.text = `${customFormat(numberOfMachines, 1)}`;
    this.numberOfWorkersText.text = `${customFormat(numberOfWorkers, 1)}`;
    this.numberOfBuildingsText.text = `${customFormat(numberOfBuildings, 1)}`;

    this.stashEth.text = customFormat(stash.eth, 3);
    this.stashToken.text = customFormat(stash.token, 1);

    // clear old items
    this.resultItems.map((item) => {
      this.resultContentContainer.remove(item);
      item.destroy();
    });

    this.earnItems.map((item) => {
      this.earnContentContainer.remove(item);
      item.destroy();
    });

    const augmentTooltipX = this.popup.width * 0.78;
    // format war results
    const formattedWarResults = this.warResults.map((item) => {
      const {
        date,
        attackResults,
        defendResults,
        defenceStreakRatio = 1,
        tokenStolen,
        numberOfMachinesToEarn,
        numberOfMachinesToAttack,
        numberOfMachinesToDefend,
        numberOfPistols,
        numberOfShields,
        tokenEarnFromEarning,
        tokenEarnFromAttacking,
        augment,
      } = item;

      const outgoingAllResults = attackResults.map((r) => r.result);
      const outgoingResult = outgoingAllResults.includes('win')
        ? 'Win'
        : outgoingAllResults.includes('lose')
        ? 'Lose'
        : outgoingAllResults.includes('draw')
        ? 'Draw'
        : '-';
      const incomingResult = defendResults?.length
        ? defendResults.some((item) => item.result === 'lose')
          ? 'Lose'
          : defendResults.some((item) => item.result === 'win')
          ? 'Win'
          : 'Draw'
        : '-';

      let totalAttack = numberOfMachinesToAttack + numberOfPistols;
      if (augment?.type === 'attack-bonus') totalAttack += augment.value;
      if (augment?.type === 'attack-multiplier') totalAttack *= augment.value;

      let totalDefence = numberOfMachinesToDefend + numberOfShields;
      if (augment?.type === 'defence-bonus') totalDefence += augment.value;
      if (augment?.type === 'defence-multiplier') totalDefence *= augment.value;
      totalDefence *= defenceStreakRatio;

      return {
        date,
        outgoingResult,
        incomingResult,
        earnUnits: numberOfMachinesToEarn || 0,
        attackUnits: numberOfMachinesToAttack ? totalAttack : 0,
        defendUnits: totalDefence,
        tokenEarned: (tokenEarnFromEarning || 0) + (tokenEarnFromAttacking || 0),
        tokenLoss: tokenStolen || 0,
        topAttacker: (defendResults || []).sort((user1, user2) => user2.attackUnits - user1.attackUnits)?.[0]
          ?.userUsername,
        augment,
      };
    });

    this.resultItems = [];
    this.earnItems = [];
    for (let i = 0; i < formattedWarResults.length; i++) {
      const y = i * rowHeight;
      if (i % 2 === 1) {
        const bg1 = this.scene.add.image(this.popup.width / 2 - 90, y, 'row-container').setOrigin(0.5, 0);
        const bg2 = this.scene.add.image(this.popup.width / 2 - 90, y, 'row-container').setOrigin(0.5, 0);
        this.resultItems.push(bg1);
        this.earnItems.push(bg2);
      }

      const {
        date,
        outgoingResult,
        incomingResult,
        earnUnits,
        attackUnits,
        defendUnits,
        augment,
        tokenEarned,
        tokenLoss,
        topAttacker,
      } = formattedWarResults[i];
      const dateText1 = this.scene.add
        .text(this.popup.width * 0.05, y + rowHeight / 2, `${date}`, smallBlackBoldCenter)
        .setOrigin(0.5, 0.5);
      const dateText2 = this.scene.add
        .text(this.popup.width * 0.05, y + rowHeight / 2, `${date}`, smallBlackBoldCenter)
        .setOrigin(0.5, 0.5);
      const outgoingResultText = this.scene.add
        .text(this.popup.width * 0.21, y + rowHeight / 2, outgoingResult, {
          ...smallBlackBoldCenter,
          color: outgoingResult === 'Lose' ? '#E93D45' : colors.black,
        })
        .setOrigin(0.5, 0.5);
      const incomingResultText = this.scene.add
        .text(this.popup.width * 0.4, y + rowHeight / 2, incomingResult, {
          ...smallBlackBoldCenter,
          color: incomingResult === 'Lose' ? '#E93D45' : colors.black,
        })
        .setOrigin(0.5, 0.5);
      const earnUnitsText = this.scene.add
        .text(this.popup.width * 0.53, y + rowHeight / 2, `${customFormat(earnUnits, 2)}`, smallBlackBoldCenter)
        .setOrigin(0.5, 0.5);
      const attackUnitsText = this.scene.add
        .text(this.popup.width * 0.61, y + rowHeight / 2, `${customFormat(attackUnits, 2)}`, smallBlackBoldCenter)
        .setOrigin(0.5, 0.5);
      const defendUnitsText = this.scene.add
        .text(this.popup.width * 0.69, y + rowHeight / 2, `${customFormat(defendUnits, 2)}`, smallBlackBoldCenter)
        .setOrigin(0.5, 0.5);
      const iconView = this.scene.add
        .image(augmentTooltipX, y + rowHeight / 2, `icon-view`)
        .setOrigin(0.5, 0.5)
        .setVisible(!!augment);
      if (augment)
        iconView.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, (pointer, localX, localY, event) => {
          if (this.tooltipAugments) {
            this.tooltipAugments.setY(pointer.worldY + 200);
            this.tooltipAugments.setAugment(augment);
            this.tooltipAugments.setVisible(true);
          }
        });

      const tokenEarnedText = this.scene.add
        .text(this.popup.width * 0.23, y + rowHeight / 2, `+${formatter.format(tokenEarned)}`, smallBlackBoldCenter)
        .setOrigin(0.5, 0.5);
      const tokenEarnedIcon = this.scene.add
        .image(this.popup.width * 0.23 + tokenEarnedText.width / 2 + 40, y + rowHeight / 2, 'icon-xcoin-outlined-small')
        .setOrigin(0.5, 0.5);

      const tokenLossText = this.scene.add
        .text(this.popup.width * 0.47, y + rowHeight / 2, `-${formatter.format(tokenLoss)}`, {
          ...smallBlackBoldCenter,
          color: '#7C2828',
        })
        .setOrigin(0.5, 0.5);
      const tokenLossIcon = this.scene.add
        .image(this.popup.width * 0.47 + tokenLossText.width / 2 + 40, y + rowHeight / 2, 'icon-xcoin-outlined-small')
        .setOrigin(0.5, 0.5);

      const topAttackerText = this.scene.add
        .text(
          this.popup.width * 0.75,
          y + rowHeight / 2,
          topAttacker ? `${formatUsername({ username: topAttacker, MAX_USERNAME_LENGTH })}` : '-',
          smallBlackBoldCenter
        )
        .setOrigin(0.5, 0.5);

      this.resultItems.push(
        dateText1,
        outgoingResultText,
        incomingResultText,
        earnUnitsText,
        attackUnitsText,
        defendUnitsText,
        iconView
      );

      this.earnItems.push(dateText2, tokenEarnedText, tokenEarnedIcon, tokenLossText, tokenLossIcon, topAttackerText);
    }
    this.resultContentContainer.add(this.resultItems);
    this.earnContentContainer.add(this.earnItems);

    const resultContentContainerHeight = formattedWarResults.length * rowHeight;
    this.resultContentContainer.setSize(0, resultContentContainerHeight);

    const resultTableHeight = this.resultListContainer.height;
    const resultVisibleRatio = resultTableHeight / resultContentContainerHeight;
    this.resultThumb = this.scene.rexUI.add
      .roundRectangle({
        height: resultVisibleRatio < 1 ? resultTableHeight * resultVisibleRatio : 0,
        radius: 13,
        color: 0xe3d6c7,
      })
      .setVisible(false);

    this.resultTable = new ScrollablePanel(this.scene, {
      x: width / 2,
      y: this.resultListY + resultTableHeight / 2,
      width: this.resultListContainer.width,
      height: resultTableHeight,
      scrollMode: 'y',
      background: this.scene.rexUI.add.roundRectangle({ radius: 10 }),
      panel: { child: this.resultContentContainer, mask: { padding: 1 } },
      slider: { thumb: this.resultThumb },
      mouseWheelScroller: { focus: true, speed: 0.3 },
      space: { left: 20, right: 20, top: 20, bottom: 20, panel: 20, header: 10, footer: 10 },
    }).layout();
    if (formattedWarResults.length <= 3 || !this.visible) {
      this.resultTable.setMouseWheelScrollerEnable(false);
    } else {
      this.resultTable.setMouseWheelScrollerEnable(true);
    }
    this.add(this.resultTable);

    this.resultTable.on('scroll', (e) => {
      // console.log('scroll', e.t); // e.t === scrolled percentage
      if (this.resultThumb.visible) return;
      this.resultThumb.setVisible(true);
      this.tooltipAugments.setVisible(false);
    });

    const earnContentContainerHeight = formattedWarResults.length * rowHeight;
    this.earnContentContainer.setSize(0, earnContentContainerHeight);

    const earnTableHeight = this.earnListContainer.height;
    const earnVisibleRatio = earnTableHeight / earnContentContainerHeight;
    this.earnThumb = this.scene.rexUI.add
      .roundRectangle({
        height: earnVisibleRatio < 1 ? earnTableHeight * earnVisibleRatio : 0,
        radius: 13,
        color: 0xe3d6c7,
      })
      .setVisible(false);

    this.earnTable = new ScrollablePanel(this.scene, {
      x: width / 2,
      y: this.earnListY + earnTableHeight / 2,
      width: this.earnListContainer.width,
      height: earnTableHeight,
      scrollMode: 'y',
      background: this.scene.rexUI.add.roundRectangle({ radius: 10 }),
      panel: { child: this.earnContentContainer, mask: { padding: 1 } },
      slider: { thumb: this.earnThumb },
      mouseWheelScroller: { focus: true, speed: 0.3 },
      space: { left: 20, right: 20, top: 20, bottom: 20, panel: 20, header: 10, footer: 10 },
    }).layout();
    if (formattedWarResults.length <= 3 || !this.visible) {
      this.earnTable.setMouseWheelScrollerEnable(false);
    } else {
      this.earnTable.setMouseWheelScrollerEnable(true);
    }
    this.add(this.earnTable);

    this.earnTable.on('scroll', (e) => {
      // console.log('scroll', e.t); // e.t === scrolled percentage
      if (this.earnThumb.visible) return;
      this.earnThumb.setVisible(true);
      this.tooltipAugments.setVisible(false);
    });

    this.tooltipAugments = new AugmentTooltip(this.scene, augmentTooltipX + 250, height / 2);
    this.tooltipAugments.setVisible(false);
    this.add(this.tooltipAugments);
    this.popup.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, (pointer, localX, localY, event) => {
      this.tooltipAugments.setVisible(false);
    });
  }

  hideRaidBtn() {
    this.backBtn.setX(width / 2);
    this.attackBtn.setVisible(false);
  }

  showRaidBtn() {
    this.backBtn.setX(width / 2 - buttonWidth / 2 - btnGap / 2);
    this.attackBtn.setVisible(true);
  }
  onOpen() {
    if (this.resultTable) {
      this.resultTable.setMouseWheelScrollerEnable(true);
    }
    if (this.earnTable) {
      this.earnTable.setMouseWheelScrollerEnable(true);
    }
  }

  cleanup() {
    this.showRaidBtn();

    this.tooltipAugments?.setVisible?.(false);

    if (this.resultTable) {
      this.resultTable.setMouseWheelScrollerEnable(false);
      this.resultThumb?.setVisible(false);
    }
    if (this.earnTable) {
      this.earnTable.setMouseWheelScrollerEnable(false);
      this.earnThumb?.setVisible(false);
    }

    // clear data
    if (this.avatar) this.avatar.destroy();
    this.usernameText.text = '';
    this.numberOfMachinesText.text = '';
    this.numberOfWorkersText.text = '';
    this.numberOfBuildingsText.text = '';

    this.stashEth.text = '--';
    this.stashToken.text = '--';

    if (this.resultTable) {
      this.remove(this.resultTable);
      this.resultTable.destroy(true);
      this.resultTable = null;
    }

    if (this.resultThumb) {
      this.remove(this.resultThumb);
      this.resultThumb.destroy(true);
    }

    if (this.earnTable) {
      this.remove(this.earnTable);
      this.earnTable.destroy(true);
      this.earnTable = null;
    }

    if (this.earnThumb) {
      this.remove(this.earnThumb);
      this.earnThumb.destroy(true);
    }

    this.resultItems.map((item) => {
      this.resultContentContainer.remove(item);
      item.destroy();
    });

    this.earnItems.map((item) => {
      this.earnContentContainer.remove(item);
      item.destroy();
    });
  }
}

export default PopupWarAttackDetail;

class AugmentTooltip extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, 0, y);

    this.container = scene.add.sprite(x, 0, ``).setOrigin(0.5, 0.5);
    this.add(this.container);

    this.icon = scene.add.image(x, 30, '').setOrigin(0.5, 0.5).setScale(0.6);
    this.valueText = scene.add
      .text(x + 80, 120, ``, {
        fontSize: fontSizes.large,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5);
    this.valueText.setStroke(colors.brown, 15);

    this.add(this.icon);
    this.add(this.valueText);
  }

  setAugment({ rarity, value, type, image }) {
    let valuePrefix = '';
    let valueSuffix = '';
    switch (type) {
      // multipliers
      case 'attack-multiplier':
      case 'defence-multiplier':
      case 'earnings-multiplier':
        valueSuffix = 'x';
        break;
      case 'attack-bonus':
      case 'defence-bonus':
      case 'earnings-bonus':
      case 'xgreed-instant-bonus':
        valuePrefix = '+';
        break;
    }

    this.container.setTexture(`tooltip-${rarity}`);
    this.icon.setTexture(image);
    this.valueText.text = `${valuePrefix}${formatter.format(value)}${valueSuffix}`;
  }
}
